var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var nestRE = /^(attrs|props|on|nativeOn|class|style|hook)$/;

exports = function mergeJSXProps(objs) {
  return objs.reduce(function (a, b) {
    var aa, bb, key, nestedKey, temp;

    for (key in b) {
      aa = a[key];
      bb = b[key];

      if (aa && nestRE.test(key)) {
        // normalize class
        if (key === "class") {
          if (typeof aa === "string") {
            temp = aa;
            a[key] = aa = {};
            aa[temp] = true;
          }

          if (typeof bb === "string") {
            temp = bb;
            b[key] = bb = {};
            bb[temp] = true;
          }
        }

        if (key === "on" || key === "nativeOn" || key === "hook") {
          // merge functions
          for (nestedKey in bb) {
            aa[nestedKey] = mergeFn(aa[nestedKey], bb[nestedKey]);
          }
        } else if (Array.isArray(aa)) {
          a[key] = aa.concat(bb);
        } else if (Array.isArray(bb)) {
          a[key] = [aa].concat(bb);
        } else {
          for (nestedKey in bb) {
            aa[nestedKey] = bb[nestedKey];
          }
        }
      } else {
        a[key] = b[key];
      }
    }

    return a;
  }, {});
};

function mergeFn(a, b) {
  return function () {
    a && a.apply(this || _global, arguments);
    b && b.apply(this || _global, arguments);
  };
}

export default exports;